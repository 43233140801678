<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('menu.setting.advanced_settings')" :swiper_tabs.sync="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "AdvancedSettings",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'appli_tierce',

                swiper_tabs: [{
                        id: 'appli_tierce',
						label: 'global.appli_tierce',
						active: false,
                        href : 'applicationTierce'
					},
                    {
                        id: 'config',
                        label: 'global.config',
                        active: false,
                        href : 'configSettings'
                    },
                    {
                        id: 'api',
                        label: 'global.api',
                        active: false,
                        href : 'APIConfigSettings'
                    }
				]
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.init_component()
		},

		methods: {
            async init_component() {
                if(this.hasBreedingAccess || this.hasSaillieAccess){
                    this.swiper_tabs.push({
                        id: 'nommage',
                        label: 'global.nommage',
                        active: false,
                        href: 'raisonSocialeConfig'
                    })
                }

                if(this.hasWebCommunicationAccess) {
                    this.swiper_tabs.push({
                        id: 'web_content',
                        label: 'global.web_content_type',
                        active: false,
                        href: 'webContentType'
                    })
                }
            }
		},
        computed: {
            hasBreedingAccess() {
                return this.$store.state.userAccess.hasBreedingAccess
            },
            hasSaillieAccess() {
                return this.$store.state.userAccess.hasSaillieAccess
            },
            hasWebCommunicationAccess() {
                return this.$store.state.userAccess.hasWebCommunicationAccess
            }
        },
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
